import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { FormattedMessage } from "react-intl"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <div className="flex-grow flex flex-col items-center justify-center">
      <h1 className="font-semibold text-6xl tracking-wide text-gray-700">
        404
      </h1>
      <p className="font-light text-2xl text-gray-700 leading-10">
        <FormattedMessage id="404.header" />
      </p>
      <p className="font-light leading-10 text-gray-500">
        <FormattedMessage id="404.description" />
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
